import React, { lazy, useState, useEffect } from "react";
import { withConsumer } from "./helpers/CacheContext";
import Loader from "./components/Loader";

const PageListItem = lazy(() =>
  import("./components/PageListItem/PageListItem")
);
const PageContent = lazy(() => import("./components/PageContent"));
const PageList = lazy(() => import("./components/PageList"));

const Routing = (props) => {
  const { fetchKirbyData, fetchSOALData, location } = props;
  const [content, setContent] = useState(null);
  useEffect(() => {
    setContent(null); // Reset content when path changes
  }, [location.pathname]);

  useEffect(() => {
    const loadContent = async () => {
      const path = location.pathname.substring(1);
      const parents = path.split("/");
      const slug = parents.pop();
      let kirbyPageQuery = {
        query: `page('${parents.join("/") + "/" + slug}')`,
        select: {
          id: true,
          title: true,
          pagetype: true,
          resource: true,
          filterVariable: "page.filtervariable.value",
          offerimg: "page.offerimg.toFile",
          layout: {
            query: "page.editor.toLayouts",
            select: {
              columns: {
                query: "layout.columns",
                select: {
                  width: true,
                  span: true,
                  blocks: true,
                },
              },
            },
          },
          blocks: {
            query: "page.editor.toBlocks",
            select: {
              id: true,
              type: true,
              headline: true,
              name: "block.name.value",
              text: "block.text.kirbytext",
              html: "block.toHtml",
              image: "block.image.toFile",
              caption: "block.caption",
              images: {
                query: "block.images.toFiles",
                select: {
                  url: true,
                },
              },
            },
          },

          description: "page.description.kirbytext",
          widgets: "page.widgetselect.yaml",
          sidebar: {
            query: "page.sidebar.toBlocks",
            select: {
              id: true,
              type: true,
              headline: true,
              name: "block.name.value",
              text: "block.text.kirbytext",
              html: "block.toHtml",
              image: "block.image.toFile",
              caption: "block.caption",
              images: {
                query: "block.images.toFiles",
                select: {
                  url: true,
                },
              },
            },
          },
          subpages: {
            query: "page.children",
            select: {
              id: true,
              title: true,
              pagetype: true,
              description: "page.text.kirbytext",
              offerimg: "page.offerimg.toFile",
              pagelink: true,
            },
          },
        },
      };

      // Adjust query for parent pages
      if (parents.length > 0 && slug != "team") {
        kirbyPageQuery.query = `page('${parents[0]}')`;
      }

      try {
        const kirbyData = await fetchKirbyData(kirbyPageQuery);
        const { result } = kirbyData;

        if (result.pagetype === "list") {
          for (let i = 0; i < result.subpages.length; i++) {
            if (result.subpages[i].id === path) {
              kirbyPageQuery.query = `page('${path}')`;
              fetchKirbyData(kirbyPageQuery).then((data) =>
                setContent(data.result)
              );
              return;
            }
          }

          const listItems = await fetchSOALData(result.resource);
          const updatedData = { ...result, items: listItems };

          // Find the specific list item
          if (listItems) {
            const item = Object.values(listItems).find(
              (i) =>
                (i.permalink && i.permalink === slug) ||
                (i.id && i.id.toString() === slug) ||
                (i.seminarnummer &&
                  i.seminarnummer.toLowerCase() === slug.toLowerCase())
            );

            if (item) {
              setContent({
                item,
                pagetype: "listitem",
                title: updatedData.title,
                path: updatedData.path,
                widgets: updatedData.widgets,
                image: updatedData.image,
                listContent: updatedData.listtexts,
              });
              return;
            }
          }
          setContent(updatedData); // Set content if no specific item is found
        } else {
          kirbyPageQuery.query = `page('${parents.join("/") + "/" + slug}')`;
          const kirbyData = await fetchKirbyData(kirbyPageQuery);
          setContent(kirbyData.result); // Setze Content auf die normale Seite
          return;
        }
      } catch (error) {}
    };

    loadContent();
  }, [fetchKirbyData, fetchSOALData, location.pathname]);

  if (!content) {
    return <Loader />;
  }
  // Render based on the page type
  switch (content.pagetype) {
    case "list":
      return <PageList content={content} location={location} />;
    case "listitem":
      return (
        <PageListItem
          content={content}
          location={location}
          widgets={content.widgets}
          image={content.image}
          listContent={content.listContent}
        />
      );
    case "content":
      return <PageContent content={content} location={location} />;
    default:
      return <Loader />;
  }
};

export default withConsumer(Routing);

const KIRBY_URL = process.env.REACT_APP_KIRBY_URL ?? "";
const headers = {
  Authorization:
    "Basic " +
    Buffer.from(
      `${process.env.REACT_APP_KIRBY_USER}:${process.env.REACT_APP_KIRBY_PASSWORD}`
    ).toString("base64"),
  "Content-Type": "application/json",
};
const apiPath = "/api/query";

const fetchKirbyAPI = async (query, cache) => {
  const cachedData = cache && cache["[KIRBY_QUERY]" + query.query];

  if (cachedData) return cachedData;

  const requestOptions = {
    method: "POST", // POST-Anfrage
    headers: headers,
    body: JSON.stringify(query), // KQL-Abfrage als JSON im Body
  };

  try {
    const response = await fetch(KIRBY_URL + apiPath, requestOptions);

    if (!response.ok) {
      // Bessere Fehlerbehandlung
      const errorResponse = await response.json();
      throw new Error(
        `Error ${response.status}: ${
          errorResponse.message || response.statusText
        }`
      );
    }

    const json = await response.json();

    if (cache) {
      cache["[KIRBY_QUERY]" + query] = json; // Cache speichern
    }

    return json;
  } catch (error) {
    console.error("Fetch error:", error.message);
    return null;
  }
};

export default fetchKirbyAPI;

const SOAL_URL = process.env.REACT_APP_SOAL_API_URL ?? "";
const apiToken = process.env.REACT_APP_SOAL_API_TOKEN ?? "";

const fetchSOALAPI = (path = "/", cache, method = "GET", query = "") => {
  // Check if data is already in cache
  const cacheKey = `[SOAL]${path}${method}${query}`;
  if (cache && cache[cacheKey]) {
    return Promise.resolve(cache[cacheKey]);
  }

  // Build the URL for GET requests
  // Build the URL for GET requests
  const doFetch = (path, method) => {
    let url = SOAL_URL + path;

    if (method === "GET") {
      // Check if the path already has a query parameter (contains '?')
      const hasQueryInPath = url.includes("?");
      // Add token and query parameters based on whether '?' is already present
      url += hasQueryInPath
        ? `&token=${apiToken}` + (query ? `&${query}` : "")
        : `?token=${apiToken}` + (query ? `&${query}` : "");

      return fetch(url, { method });
    } else if (method === "POST") {
      const formData = new window.FormData();
      formData.set("token", apiToken);

      return fetch(SOAL_URL + path, {
        method,
        body: formData,
      });
    }
  };

  // Make the fetch request
  return doFetch(path, method)
    .then((response) => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      }
      return response.json();
    })
    .then((json) => {
      // Save the result in cache
      if (cache) cache[cacheKey] = json;
      return json;
    })
    .catch((e) => {
      console.error("Couldn't fetch data => ", e.message);
      return {};
    });
};

export default fetchSOALAPI;

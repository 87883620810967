import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withConsumer } from "../helpers/CacheContext";
import logoWhite from "../assets/soal-logo_white.svg";
import soalMap from "../assets/soalde-karte.jpg";

const Footer = ({ fetchKirbyData, fetchSOALData, cookieState }) => {
  const [siteMap, setSiteMap] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch content for the footer
        const kirbyHomeQuery = { query: "site.footerinfo.markdown" };
        const homeData = await fetchKirbyData(kirbyHomeQuery, true);
        setContent(homeData.result);

        // Fetch Kirby menu data
        const kirbyMenuQuery = {
          query: "site.children.listed",
          select: {
            id: true,
            title: true,
            uri: true,
            slug: true,
            showinsitemap: true,
            pagetype: true,
            resource: true,
            pagelink: true,
            filtervariable: true,
            children: {
              select: {
                title: true,
                uri: true,
                pagetype: true,
                showinsitemap: true,
                slug: true,
              },
            },
          },
        };

        const menuData = await fetchKirbyData(kirbyMenuQuery, true);
        let updatedSiteMap = menuData.result;

        const promises = updatedSiteMap.map(async (element) => {
          if (element.pagetype === "link") {
            const link = element.pagelink.replace("- ", "");
            const pageData = await fetchKirbyData({
              query: `page('${link}')`,
              select: {
                id: true,
                title: true,
                uri: true,
                slug: true,
                showinsitemap: true,
                pagetype: true,
                resource: true,
                filtervariable: "page.filtervariable.value",
                offerimg: "page.offerimg.toFile",
                layout: {
                  query: "page.editor.toLayouts",
                  select: {
                    columns: {
                      query: "layout.columns",
                      select: {
                        width: true,
                        span: true,
                        blocks: true,
                      },
                    },
                  },
                },
                blocks: {
                  query: "page.editor.toBlocks",
                  select: {
                    id: true,
                    type: true,
                    headline: true,
                    name: "block.name.value",
                    text: "block.text.kirbytext",
                    html: "block.toHtml",
                    image: "block.image.toFile",
                    caption: "block.caption",
                    images: {
                      query: "block.images.toFiles",
                      select: {
                        url: true,
                      },
                    },
                  },
                },
                description: "page.description.kirbytext",
                widgets: "page.widgetselect.yaml",
                sidebar: "page.sidebar.toBlocks",
                subpages: {
                  query: "page.children",
                  select: {
                    id: true,
                    title: true,
                    pagetype: true,
                    description: "page.text.kirbytext",
                    offerimg: "page.offerimg.toFile",
                    pagelink: true,
                    uri: true,
                    showinmenu: true,
                    slug: true,
                  },
                },
              },
            });
            element.uri = pageData.result.uri;
            element.children = pageData.result.subpages;
          } else if (element.pagetype === "list") {
            const soalData = await fetchSOALData(element.resource);
            const categories = soalData.reduce((acc, item) => {
              const category = item[element.filtervariable];
              if (category) acc.add(category);
              return acc;
            }, new Set());
            element.filterInMenu = [...categories];
          }
        });

        await Promise.all(promises);
        setSiteMap([...updatedSiteMap]); // Trigger re-render with updated data
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error);
      }
    };

    loadData();
  }, [fetchKirbyData, fetchSOALData]);

  return (
    <div className="footer-wrap hidden-print">
      <div className="footer-block">
        <div className="footer-block-big footer-block--img">
          {cookieState ? (
            <iframe
              title="gmaps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.3461023051136!2d9.941804716342618!3d53.55158888002253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18f67f543137d%3A0xb94da471d9f56a5a!2sSOAL%20e.V.%2C%20Alternativer%20Wohlfahrtsverband%2C%20Sozial%20%26%20Alternativ!5e0!3m2!1sen!2sde!4v1605705869982!5m2!1sen!2sde"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          ) : (
            <img
              className="footer-block--soalmap"
              src={soalMap}
              alt="soal map"
            />
          )}
        </div>
        <div className="footer-block-small">
          <div className="footer-block--content is-bg-magenta">
            <div className="footer-block--content-headline">
              <img
                className="footer-block--logo"
                src={logoWhite}
                alt="white logo"
              />
            </div>
            {content && (
              <div
                className="footer-block--content-text"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            )}
          </div>
        </div>
      </div>
      <div className="footer-block">
        <div className="footer-block--content is-full">
          <div className="footer-block--nav">
            {siteMap &&
              siteMap.map((item, i) => {
                if (item.showinsitemap === "false") return null;
                return (
                  <div className="footer-block--nav-block" key={i}>
                    <div className="footer-block--nav-headline">
                      <Link to={`/${item.uri}`}>{item.title}</Link>
                    </div>

                    {item.pagetype === "list" &&
                      item.filterInMenu?.map((filterItem, j) => (
                        <div className="footer-block--nav-link" key={j}>
                          <Link
                            to={{
                              pathname: `/${item.uri}`,
                              state: { preFilter: filterItem },
                            }}
                          >
                            {filterItem}
                          </Link>
                        </div>
                      ))}

                    {item.children?.map((subitem, j) => {
                      if (subitem.showInSitemap === "false") return null;
                      return (
                        <div className="footer-block--nav-link" key={j}>
                          <Link to={`/${subitem.uri}`}>{subitem.title}</Link>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withConsumer(Footer);

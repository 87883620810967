import React, { lazy, Suspense, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import customHistory from "./history";
import { ReactComponent as Icons } from "./assets/icons.svg";

import Header from "./components/Header/Header";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import GoogleAnalytics from "./components/GoogleAnalytics";
import CookieBannerGDPR from "./components/CookieBannerGDPR";
import Routing from "./Routing";
import ScrollToTop from "./utils/ScrollToTop";

const Home = lazy(() => import("./components/Home"));
const SearchResults = lazy(() => import("./components/SearchResults"));

const App = () => {
  const [statisticsCookieState, setStatisticsCookieState] = useState(false);
  const [marketingCookieState, setMarketingCookieState] = useState(false);

  const handleSetStatistics = () => {
    setStatisticsCookieState(true);
  };
  const handleSetMarketing = () => {
    setMarketingCookieState(true);
  };

  return (
    <main>
      <Router history={customHistory}>
        <ScrollToTop />
        <React.Fragment>
          <Icons style={{ display: "none" }} />
          <div className="main" id="top">
            <Header />
            <GoogleAnalytics cookieState={statisticsCookieState} />
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route
                  path="/suche/:suchanfrage"
                  render={(props) => <SearchResults {...props} />}
                />
                <Route path="/" render={(props) => <Routing {...props} />} />{" "}
              </Switch>
            </Suspense>

            <Footer cookieState={marketingCookieState} />
          </div>
        </React.Fragment>
      </Router>

      <CookieBannerGDPR
        onAcceptStatistics={handleSetStatistics}
        onAcceptMarketing={handleSetMarketing}
      />
    </main>
  );
};

export default App;

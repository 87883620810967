import React from "react";
import fetchKirbyAPI from "./fetchKirbyAPI";
import fetchSOALAPI from "./fetchSOALAPI";

const CacheContext = React.createContext();
let cache = {};

function CacheProvider(props) {
  // Funktion zum Abrufen eines bestimmten Ansprechpartners über die SOAL API
  const fetchAnsprechpartner = async (name) => {
    const data = await fetchSOALAPI("/team", cache);
    const formattedName = name.replace(/\s+/g, "").toLowerCase(); // Optimierte Leerzeichen-Entfernung und Umwandlung zu Kleinbuchstaben

    for (const [department, teamMembers] of Object.entries(data)) {
      const person = Object.values(teamMembers).find(
        (member) =>
          (member.vorname + member.nachname)
            .replace(/\s+/g, "")
            .toLowerCase() === formattedName
      );
      if (person) return person;
    }
    return null; // Rückgabe, falls keine Person gefunden wurde
  };

  // Funktion für KQL-Abfragen an die Kirby API
  const fetchKirbyData = async (query) => {
    let cacheKey = `[KIRBY_QUERY]${query.query}`;

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }

    const response = await fetchKirbyAPI(query, cache);
    if (response) {
      cache[cacheKey] = response; // Antwort im Cache speichern
    }
    return response;
  };

  // Funktion zum Abrufen von Daten von der SOAL API
  const fetchSOALData = async (path, method = "GET", query = "") => {
    const cacheKey = `[SOAL]${path}${query ? `?${query}` : ""}`;

    // Rückgabe aus dem Cache, falls vorhanden
    if (cache[cacheKey]) {
      return cache[cacheKey];
    }

    const response = await fetchSOALAPI(path, cache, method, query);
    if (response) {
      cache[cacheKey] = response; // Antwort im Cache speichern
    }
    return response;
  };

  return (
    <CacheContext.Provider
      value={{ fetchKirbyData, fetchSOALData, fetchAnsprechpartner, cache }}
    >
      {props.children}
    </CacheContext.Provider>
  );
}

// HOC für Komponenten, die den CacheContext nutzen möchten
const withConsumer = (WrappedComponent) => (props) =>
  (
    <CacheContext.Consumer>
      {(value) => (
        <WrappedComponent
          {...props}
          fetchKirbyData={value.fetchKirbyData}
          fetchSOALData={value.fetchSOALData}
          fetchAnsprechpartner={value.fetchAnsprechpartner}
          cache={value.cache}
        />
      )}
    </CacheContext.Consumer>
  );

export { CacheContext, CacheProvider, withConsumer };
